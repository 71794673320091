$(function () {
    var w = $(window).width();


    if($('.service-page__navigation').length > 0) {
        setTimeout(function () {
            serviceNav();

            $(window).resize(function() {
                serviceNav();
            });

            var
                activeLink = $('.service-page__navigation').find('a'),
                activeHeight = activeLink.height() + 18,
                listHeight = $('.aside__container').height();

            activeLink.click(function(e){
                e.preventDefault();

                if(!activeLink.hasClass('openedNav')) {
                    $('.service-page__navigation').height(listHeight);
                    activeLink.addClass('openedNav');
                } else {
                    $('.service-page__navigation').height(activeHeight);
                    activeLink.removeClass('openedNav');
                }

            });
        }, 500);
    }


    // Якорні посилання
    $('.anker[href^="#"]').bind('click.smoothscroll',function (e) {
        e.preventDefault();

        var target = this.hash,
            $target = $(target);

        $('html, body').stop().animate({'scrollTop': $target.offset().top  }, 1000, 'swing', function () {
            window.location.hash = target;
        });
    });



    //Высота wrapper
    var
        windowHeight = $(window).height(),
        headerHeight = $('.header').height(),
        footerHeight = $('.footer').height(),
        wrapperHeight = windowHeight - headerHeight - footerHeight - 5;

    $('.wrapper').css({'min-height' : wrapperHeight+'px'});
    $('.error').css({'min-height' : wrapperHeight+'px'});

    $( window ).resize(function() {
        var
            windowHeight = $(window).height(),
            headerHeight = $('.header').height(),
            footerHeight = $('.footer').height(),
            wrapperHeight = windowHeight - headerHeight - footerHeight;

        $('.wrapper').css({'min-height' : wrapperHeight+'px'});
        $('.error').css({'min-height' : wrapperHeight+'px'});
    });




    //Анімації при скролі
    if(w > 0) {
        $(window).scroll(function (e) {
            var
                windowScroppTop = $(window).scrollTop(),
                windowHeight = $(window).height(),
                windowBottom = windowScroppTop + windowHeight;


            $('.animate').each(function (e) {
                var
                    part = $(this),
                    partOffset = part.offset().top,
                    dataDelay = part.data().delay;

                if (windowBottom > partOffset - 20) {
                    setTimeout(function () {
                        part.removeClass('animate');
                    }, dataDelay);
                }
            });

        });
    }

    if(w > 0) {
        $('.onLoad').each(function (e) {
            var
                part = $(this),
                dataDelay = part.data().delay;

            setTimeout(function () {
                part.removeClass('animate');
            }, dataDelay);
        });
    }




    //Анимация при наведении на соц.иконку
    $('.round_animation').mouseenter(function () {
        var
            $this = $(this),
            an2 = $this.find('.an_2'),
            an3 = $this.find('.an_3'),
            an4 = $this.find('.an_4'),
            an5 = $this.find('.an_5');

        an2.css({'opacity' : '1'});

        setTimeout(function () {
            an3.css({'opacity' : '1'});
        }, 50);
        setTimeout(function () {
            an4.css({'opacity' : '1'});
        }, 100);
        setTimeout(function () {
            an5.css({'opacity' : '1'});
        }, 150);
    });

    $('.round_animation').mouseleave(function () {
        var
            $this = $(this),
            an2 = $this.find('.an_2'),
            an3 = $this.find('.an_3'),
            an4 = $this.find('.an_4'),
            an5 = $this.find('.an_5');

        an5.css({'opacity' : '0'});

        setTimeout(function () {
            an4.css({'opacity' : '0'});
        }, 50);
        setTimeout(function () {
            an3.css({'opacity' : '0'});
        }, 100);
        setTimeout(function () {
            an2.css({'opacity' : '0'});
        }, 150);
    });




    // Слайдер с отзывами
    //Подключается owlCarousel для слайдэра с отзывами, и добавляются кастомные кнопки для навигации
    $(document).ready(function(){
        var owl = $(".carousel_reviews").owlCarousel({
            smartSpeed: 500,
            dots: true,
            mouseDrag: true,
            autoplay: false,
            addClassActive: true,
            loop: true,
            nav: true,
            touchDrag: true,
            dotsSpeed: 500,
            autoplaySpeed: 500,
            stagePadding: 0,
            margin: 0,
            responsive:{
                0:{
                    items: 1
                },
                681:{
                    items: 2
                },
                991:{
                    dots: false,
                    items: 3,
                    mouseDrag: false,
                    nav: false,
                    touchDrag: false,
                }
            }
        });


        $('.customPrevBtnRev').click(function() {
            owl.trigger('next.owl.carousel');
        });
        $('.customNextBtnRev').click(function() {
            owl.trigger('prev.owl.carousel');
        });

        var dotsWidth = $(".carousel_reviews").find('.owl-dots').width();
        $('.customCarouselReviews').width(dotsWidth);

        $( window ).resize(function() {
            setTimeout(function () {
                var dotsWidth = $(".carousel_reviews").find('.owl-dots').width();
                $('.customCarouselReviews').width(dotsWidth);
            }, 400);
        });


        $('.reviews__link').click(function(e) {
            e.preventDefault();
            var
                $this = $(this),
                dock = $this.find('.review-img').clone();

            $('.popup__review-conteiner').html(dock);

            $('#popup__review').fadeIn(200);
            $('html').css({'overflow' : 'hidden'});
        });
    });


    // Слайдер с партнерами
    //Подключается owlCarousel для слайдэра с партнерами, и добавляются кастомные кнопки для навигации
    $(document).ready(function(){
        var owl = $(".carousel_partnears").owlCarousel({
            smartSpeed: 500,
            dots: true,
            items: 2,
            mouseDrag: true,
            autoplay: true,
            addClassActive: true,
            loop: true,
            nav: true,
            touchDrag: true,
            dotsSpeed: 500,
            autoplaySpeed: 500,
            margin: 0,
            stagePadding: 0,
            responsive:{
                0:{
                    items: 3,
                    center: true
                },
                568:{
                    items: 2,
                    center: false
                },
                767:{
                    items: 3,
                    center: false
                },
                991:{
                    items: 4,
                    center: false
                },
                1600:{
                    items: 5,
                    center: false
                }
            }
        });


        $('.customPrevBtn').click(function() {
            owl.trigger('next.owl.carousel');
        });
        $('.customNextBtn').click(function() {
            owl.trigger('prev.owl.carousel');
        });

        var dotsWidth = $(".carousel_partnears").find('.owl-dots').width();
        $('.customCarouselPartnears').width(dotsWidth);

        $( window ).resize(function() {
            setTimeout(function () {
                var dotsWidth = $(".carousel_partnears").find('.owl-dots').width();
                $('.customCarouselPartnears').width(dotsWidth);
            }, 400);
        });

    });




    // Слайдер о компании
    //Подключается owlCarousel для слайдэра о компании, и добавляются кастомные кнопки для навигации
    $(document).ready(function(){
        var owl = $(".carousel_about").owlCarousel({
            smartSpeed: 500,
            dots: true,
            items: 1,
            mouseDrag: false,
            autoplay: false,
            addClassActive: true,
            loop: true,
            nav: true,
            touchDrag: false,
            animateOut: 'fadeOut',
            dotsSpeed: 500
        });


        $('.customPrevBtnAbout').click(function() {
            owl.trigger('next.owl.carousel');
        });
        $('.customNextBtnAbout').click(function() {
            owl.trigger('prev.owl.carousel');
        });

        var dotsHeight = $(".carousel_about").find('.owl-dots').height();
        $('.customCarouselAbout').height(dotsHeight);

        $( window ).resize(function() {
            setTimeout(function () {
                var dotsHeight = $(".carousel_about").find('.owl-dots').height();
                $('.customCarouselAbout').height(dotsHeight);
            }, 400);
        });


        var
            sliderText = $(".carousel_about").find('.owl-item.active').find('.carousel_about__text').text();

        $('.about-us__text-container').append('<div class="carousel_about__text-inner showAnimate">'+sliderText+'</div>');

        setTimeout(function () {
            $('.carousel_about__text-inner').removeClass('showAnimate');
        }, 1000);

        owl.on('changed.owl.carousel', function(event) {
            setTimeout(function () {
                var
                    sliderText = $(".carousel_about").find('.owl-item.active').find('.carousel_about__text').text();

                $('.carousel_about__text-inner').addClass('hideAnimate');

                setTimeout(function () {
                    $('.about-us__text-container').append('<div class="carousel_about__text-inner showAnimate">'+sliderText+'</div>');
                }, 100);
                setTimeout(function () {
                    $('.carousel_about__text-inner').removeClass('showAnimate');
                }, 150);
            }, 100);
        })
    });



    //Слайдер с примерами
    $(document).ready(function(){
        var owl = $(".carousel_example").owlCarousel({
            smartSpeed: 500,
            dots: false,
            items: 4,
            mouseDrag: true,
            autoplay: false,
            addClassActive: true,
            loop: true,
            nav: true,
            touchDrag: true,
            dotsSpeed: 500,
            autoplaySpeed: 500,
            margin: 30,
            center: true,
            stagePadding: 0,
            responsive: {
                1199: {
                    center: false
                }
            }
        });


        $('.exampleWork').click(function(e) {
            e.preventDefault();
            var
                $this = $(this),
                dock = $this.find('.example__photo_inner img').clone(),
                coverRemove = dock.removeClass('cover');

            $('.popup__work-conteiner').html(coverRemove);

            $('#popup__work').fadeIn(200);
            $('html').css({'overflow' : 'hidden'});
        });

        $('.exampleDock').click(function(e) {
            e.preventDefault();
            var
                $this = $(this),
                dock = $this.find('.example__photo_inner img').clone(),
                coverRemove = dock.removeClass('cover');

            $('.popup__example-conteiner').html(coverRemove);

            $('#popup__example').fadeIn(200);
            $('html').css({'overflow' : 'hidden'});
        });

    });



    //Открытие меню
    $('.hamburger-nav').click(function(e) {
        e.preventDefault();

        var
            windowWidth = $(window).width(),
            windowHeight = $(window).height();

        console.log(windowHeight)

        if(!$(this).hasClass('opened_menu')) {
            $('.mobile-nav').width(windowWidth * 2);
            $('.mobile-nav').height((windowHeight - 60) * 2);
            $('.mobile-nav__container').height(windowHeight - 75);

            $(this).addClass('opened_menu');
            $('.mobile-nav').addClass('js-opened-nav');
            $('html').css({'overflow' : 'hidden'});
        } else {
            $(this).removeClass('opened_menu');
            $('.mobile-nav').removeClass('js-opened-nav');
            $('html').css({'overflow' : 'visible'});
            $('.mobile-nav').width(0);
            $('.mobile-nav').height(0);
        }
    });

    $( window ).resize(function() {
        var
            windowWidth = $(window).width(),
            windowHeight = $(window).height();

        if($('.hamburger-nav').hasClass('opened_menu')) {
            $('.mobile-nav').width(windowWidth * 2);
            $('.mobile-nav').height((windowHeight - 60) * 2);
            $('.mobile-nav__container').height(windowHeight - 75);
        }
    });






    //Ефект печати текста
    if($('.mission').length > 0) {
        var
            windowHeightStat = $(window).height(),
            typeItOffsetStat = $('.mission').offset().top;

        if (windowHeightStat > typeItOffsetStat) {
            var
                missionText = $('.mission-text').text();

            $('.atartTyping').typeIt({
                strings: [missionText],
                speed: 35,
                waitUntilVisible: true
            });

            $('.mission').removeClass('atartTyping');
        }

        $(window).scroll(function (e) {
            var
                windowScroppTop = $(window).scrollTop(),
                windowHeight = $(window).height(),
                windowBottom = windowScroppTop + windowHeight,
                typeItOffset = $('.mission').offset().top;


            if (windowBottom > typeItOffset - 20) {
                $('.mission').click();
                $('.mission').removeClass('atartTyping');
            }

        });

        $('.mission').click(function (e) {
            var
                missionText = $('.mission-text').text();

            $('.atartTyping').typeIt({
                strings: [missionText],
                speed: 35,
                waitUntilVisible: true
            });
        });
    }



    //Закрытие попапов
    $('.popup__underlay, .close-popup').click(function (e) {
        e.preventDefault();

        $('.popup-overlay').fadeOut(200);
        $('html').css({'overflow' : 'visible'});

        if($('.popup__video-conteiner').length > 0) {
            setTimeout(function () {
                $('.popup__video-conteiner').html('');
            }, 300);
        }
    });



    //Больше ртзывов
    if($('.reviews--all').length > 0) {
        if ($('.reviews__item').length > 6) {
            $('.reviews__item').eq(6).prevAll().addClass('showReviews');
            $('.reviews__item').css({'display': 'none'});
            $('.button-more-reviews').css({'display': 'flex'});
        }

        $('.button-more-reviews').click(function (e) {
            e.preventDefault();

            if (!$(this).hasClass('showed')) {
                $('.reviews__item').addClass('showReviewsMore');
                $(this).addClass('showed');
            } else {
                $('.reviews__item').removeClass('showReviewsMore');
                $(this).removeClass('showed');
            }

        });
    }



    //Пагинация в блоге
    if($('.blog__page-num:first-child').hasClass('blog__page-active')) {
        $('.blog__pagination-prev').hide();
    }
    if($('.blog__page-num:last-child').hasClass('blog__page-active')) {
        $('.blog__pagination-next').hide();
    }


    //Открытие попапа с формой
    $('.contact__button').click(function (e) {
        e.preventDefault();

        $('#popup__form').fadeIn(200);
        $('html').css({'overflow' : 'hidden'});
    });


    //Валідація полів
    $('.form_button').click(function(e){
        e.preventDefault();

        var
            $this = $(this),
            thisForm = $this.closest('form'),
            nameInput = thisForm.find('.nameInput'),
            mailInput = thisForm.find('.mailInput');

        if(nameInput.val().length < 1) {
            nameInput.addClass('invalid');
        } else {
            nameInput.removeClass('invalid');
        }


        var email = mailInput.val();
        if(isValidEmailAddress(email)) {
            mailInput.removeClass('invalid');
        } else {
            mailInput.addClass('invalid');
        }



        if(nameInput.val().length > 0 && isValidEmailAddress(email)) {
            var th = thisForm;
            $.ajax({
                type: "POST",
                url: "/mail.php",
                data: th.serialize()
            }).done(function() {
                $('.popup-overlay').fadeOut(200);
            });
            return false;
        }

    });

    function isValidEmailAddress(emailAddress) {
        var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        return pattern.test(emailAddress);
    }

    $('input').focus(function(){
        $(this).removeClass('invalid');
    });




    //Две колонки с флексбокс
    setTimeout(function () {
        if(w > 991) {
            $('.twoColl').each(function(e){
                var
                    list = $(this),
                    itemCount = list.find('.collItem').length,
                    breakItemNum = Math.ceil(itemCount / 2),
                    allPrev = list.find('.collItem').eq(breakItemNum).prevAll(),
                    allNext = list.find('.collItem').eq(breakItemNum - 1).nextAll(),
                    allPrevSum = 0,
                    allNextSum = 0;

                allPrev.each(function(e){
                    var
                        thisHeight = $(this).height(),
                        thisPadTop = parseInt($(this).css('padding-top')),
                        thisPadBot = parseInt($(this).css('padding-bottom')),
                        thisMarTop = parseInt($(this).css('margin-top')),
                        thisMarBot = parseInt($(this).css('margin-bottom')),
                        allPad = parseInt(thisPadTop + thisPadBot + thisMarTop + thisMarBot);

                    allPrevSum += parseFloat(thisHeight + allPad);
                });

                allNext.each(function(e){
                    var
                        thisHeight = $(this).height(),
                        thisPadTop = parseInt($(this).css('padding-top')),
                        thisPadBot = parseInt($(this).css('padding-bottom')),
                        thisMarTop = parseInt($(this).css('margin-top')),
                        thisMarBot = parseInt($(this).css('margin-bottom')),
                        allPad = parseInt(thisPadTop + thisPadBot + thisMarTop + thisMarBot);

                    allNextSum += parseFloat(thisHeight + allPad);
                });

                if(allPrevSum > allNextSum) {
                    list.height(allPrevSum );
                } else {
                    list.height(allNextSum);
                }
            });
        }
    }, 500);



    //Скролл текста на новости
    if((w) > 991) {
        var
            photoHeight = $('.article__photo').height(),
            titleHeight = $('.article__title').height() + 20;

        if(photoHeight > 50) {
            $('.article__text').height(photoHeight);
            $('.article__desc').css({'top' : titleHeight+'px'});
        } else {
            setTimeout(function () {
                var
                    photoHeight = $('.article__photo').height(),
                    titleHeight = $('.article__title').height() + 20;

                $('.article__text').height(photoHeight);
                $('.article__desc').css({'top' : titleHeight+'px'});
            }, 500);
        }
    }

    $(window).resize(function() {
        var
            photoHeight = $('.article__photo').height(),
            titleHeight = $('.article__title').height() + 20;

        $('.article__text').height(photoHeight);
        $('.article__desc').css({'top': titleHeight + 'px'});
    });



    //Открытие попапа с видео
    $('.article__youtube-button').click(function(e){
        e.preventDefault();

        var
            video = $('.video').html();

        $('.popup__video-conteiner').html(video);
        setTimeout(function () {
            $('#popup__video').fadeIn(500);
            $('html').css({'overflow' : 'hidden'});
        }, 300);
    });


});


//Боковое меню на странице услуги
function serviceNav() {
    var w = $(window).width();

    if(w > 1199) {
        $('.service-page__navigation').css({'height' : 'auto'});
        $(window).scroll(function (e) {
            var
                windowScroppTop = $(window).scrollTop(),
                windowHeight = $(window).height(),
                footerOffset = $('footer').offset().top,
                headerHeight = $('header').height(),
                bottomPos = (footerOffset - windowScroppTop - windowHeight) * -1;


            if(windowScroppTop >= headerHeight) {
                $('.service-page__navigation').css({'top' : headerHeight+'px', 'position' : 'fixed'});
            } else {
                $('.service-page__navigation').css({'top' : '76px', 'position' : 'absolute'});
            }

            if(windowScroppTop + windowHeight >= footerOffset) {
                $('.aside__container').height(windowHeight - bottomPos - headerHeight);
            } else {
                $('.aside__container').height(windowHeight - headerHeight);
            }

        });
    } else {
        var
            activeLink = $('.service-page__navigation').find('a'),
            activeHeight = activeLink.height() + 18,
            listHeight = $('.aside__container').height();

        if(activeLink.hasClass('openedNav')) {
            $('.service-page__navigation').height(listHeight);
        } else {
            $('.service-page__navigation').height(activeHeight);
        }
    }
}